import { CommonModule, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { EffyIcons, EffyLinkComponent, EffyLinkSize, EffyLinkStyle } from '@effy-tech/angular-common';
import { MediaQueryService } from '../../../services';

@Component({
  selector: 'prc-lib-back-button',
  standalone: true,
  imports: [CommonModule, EffyLinkComponent, MatIconModule],
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  private readonly mediaQueryService = inject(MediaQueryService);
  isDesktop = toSignal(this.mediaQueryService.isDesktop$);

  readonly returnStyle = EffyLinkStyle.MIDNIGHT_BLUE
  readonly returnIcon = EffyIcons.CHEVRON_LEFT;
  readonly returnSizeDesktop = EffyLinkSize.MEDIUM
  readonly returnSizeMobile = EffyLinkSize.SMALL

  constructor(private location: Location) {}

  onClick(): void {
    this.location.back();
  }
}
