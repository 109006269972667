let TRUSTPILOT_INSTANCE_NUMBER = 0;

export class TrustpilotBase {
  // need to set an unique id for trustpilot HTML element to work properly
  public readonly trustpilotId = `trustbox-${TRUSTPILOT_INSTANCE_NUMBER}`;

  constructor() {
    TRUSTPILOT_INSTANCE_NUMBER++;
  }

  init(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customWindow = window as any;
    const trustboxRef = document.getElementById(this.trustpilotId);
    customWindow.Trustpilot.loadFromElement(trustboxRef);
  }
}
