import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SherlockService } from '@prc-serenity-services/sherlock/sherlock.service';
import { CONFIRMATION_GO_TO_ELIGIBLE_ACTION } from '@prc-serenity-store/actions/confirmation.action';
import { tap } from 'rxjs';

@Injectable()
export class SherlockEffects {
  onGoToConfirmationEligible$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CONFIRMATION_GO_TO_ELIGIBLE_ACTION),
        tap(() => this.sherlockService.dispatchCreateLead())
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly sherlockService: SherlockService) {}
}
