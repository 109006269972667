import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { TrustpilotBase } from '../trustpilot-base';

@Component({
  selector: 'prc-lib-trustpilot-micro-review',
  standalone: true,
  imports: [CommonModule],
  templateUrl: 'trustpilot-micro-review.component.html',
  styleUrls: ['./trustpilot-micro-review.component.scss'],
})
export class TrustpilotMicroReviewComponent extends TrustpilotBase implements AfterViewInit {
  constructor() {
    super();
  }

  @Input() textColor: string = '#161637';

  ngAfterViewInit(): void {
    this.init();
  }
}
