import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CookiesGa } from '../../configs';
import { EnrichmentKeys, UtmModel, VulcanKeys } from '../../models';
import { getDataEnrichmentWs } from '../../values/enrichment.values';
import { AbTastyService } from '../ab-tasty/ab-tasty.service';
import { CookieConsentService } from '../cookie-consent/cookie-consent.service';
import { CookieManagerService } from '../cookie-manager/cookie-manager.service';
import { UtmService } from '../utm/utm.service';
import { EFFY_ENVIRONMENT, API_KEY } from '@effy-tech/angular-common';

@Injectable({
  providedIn: 'root',
})
export class EnrichmentService {
  private readonly effyEnv = inject(EFFY_ENVIRONMENT);
  private readonly apiKey = inject(API_KEY);
  private readonly abTastyService = inject(AbTastyService);
  private readonly httpClient = inject(HttpClient);
  private readonly utmService = inject(UtmService);
  private readonly cookieConsentService = inject(CookieConsentService);
  private readonly cookieManagerService = inject(CookieManagerService);

  enrich<T>(
    utmFunnel: string,
    pisteId: string,
    userId: string,
    works: T[] | null = null,
    additionalKeyValues: { [key in string]: unknown } | null = null
  ): Observable<unknown> {
    const utm: UtmModel = this.utmService.getData();
    const body = {
      data: {
        [EnrichmentKeys.abTasty]: this.abTastyService.getData(),
        [EnrichmentKeys.gaClientId]: this.getGaClientId(),
        [EnrichmentKeys.pisteId]: pisteId,
        [EnrichmentKeys.userId]: userId,
        [EnrichmentKeys.utm]: {
          ...(utm.source ? { [VulcanKeys.UtmSource]: utm.source } : {}),
          ...(utm.campaign ? { [VulcanKeys.UtmCampaign]: utm.campaign } : {}),
          ...(utm.medium ? { [VulcanKeys.UtmMedium]: utm.medium } : {}),
          ...(utm.content ? { [VulcanKeys.UtmContent]: utm.content } : {}),
          ...(utm.term ? { [VulcanKeys.UtmTerm]: utm.term } : {}),
          [VulcanKeys.UtmFunnel]: utmFunnel,
          ...(utm.gclid ? { [VulcanKeys.UtmGclid]: utm.gclid } : {}),
          ...(utm.referrer ? { [VulcanKeys.UtmReferrer]: utm.referrer } : {}),
        },
        ...(works ? { [EnrichmentKeys.works]: works } : {}),
        [EnrichmentKeys.cookieConsent]: this.cookieConsentService.getConsent(),
        ...(additionalKeyValues ? { ...additionalKeyValues } : {}),
      },
    };

    return this.httpClient.put(getDataEnrichmentWs(this.effyEnv, this.apiKey), body);
  }

  private getGaClientId(): string | null {
    return this.cookieManagerService.getCookie(CookiesGa.clientId);
  }
}
