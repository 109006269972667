import { LeadCivility } from '@effy-tech/angular-common';
import { createReducer, on } from '@ngrx/store';
import { Nullable, RemoveNullable, UserKeys } from '@prc-lib-core';
import { UserInitAction, UserSetData, UserUpdateAction, UserUpdateInfoAction } from '../actions/user.action';

export interface UserState {
  [UserKeys.Civility]: Nullable<LeadCivility>;
  [UserKeys.Email]: Nullable<string>;
  [UserKeys.FirstName]: Nullable<string>;
  [UserKeys.LastName]: Nullable<string>;
  [UserKeys.Phone]: Nullable<string>;
  [UserKeys.Uid]: Nullable<string>;
  [UserKeys.OptOutStatus]: boolean;
}

export type UserStateNonNullable = RemoveNullable<UserState>;

export const userInitialState: UserState = {
  [UserKeys.Civility]: null,
  [UserKeys.Email]: null,
  [UserKeys.FirstName]: null,
  [UserKeys.LastName]: null,
  [UserKeys.Phone]: null,
  [UserKeys.Uid]: null,
  [UserKeys.OptOutStatus]: false,
};

export type UserKeyType = keyof UserState;

export const userReducer = createReducer(
  userInitialState,
  on(UserInitAction, () => userInitialState),
  on(UserUpdateAction, (state, { payload }) => ({ ...state, ...payload })),
  on(UserSetData, (state, { payload }) => {
    return { ...state, ...{ [payload.key]: payload.value } };
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on(UserUpdateInfoAction, (state, { payload }) => ({ ...state, ...(payload as any) }))
);
