import { ProgressLineStatus } from "./progress-line.config";

export class ProgressLine {
  static getProgresslineLength(timeLineStatus: ProgressLineStatus): number {
    const stepLength = this.computeTimelineStep(timeLineStatus.currentStep, timeLineStatus.totalSteps);
    return timeLineStatus.totalSubSteps > 0 ?
      stepLength + this.computeTimelineSubStep(timeLineStatus.currentSubStep, timeLineStatus.totalSteps, timeLineStatus.totalSubSteps) :
      stepLength;
  }

  static computeTimelineStep(currentStep: number, totalSteps: number): number {
    return (100 / (totalSteps - 1)) * currentStep;
  }

  static computeTimelineSubStep(currentSubStep: number, totalSteps: number, totalSubSteps: number): number {
    return ((100 / (totalSteps - 1)) / (totalSubSteps + 1)) * (currentSubStep + 1);
  }
}
