import { createReducer, on } from '@ngrx/store';
import { CostEstimatorOfferWithOfferWorkType } from '@prc-lib-core';
import { RESULTS_ESTIMATE_ACTION, RESULTS_ESTIMATE_SUCCESS_ACTION } from '@prc-serenity-store/actions/results.action';

export interface ResultsState {
  data: CostEstimatorOfferWithOfferWorkType[];
}

export const resultsInitialState: ResultsState = {
  data: [],
};

export const resultsReducer = createReducer(
  resultsInitialState,
  on(RESULTS_ESTIMATE_ACTION, () => resultsInitialState),
  on(RESULTS_ESTIMATE_SUCCESS_ACTION, (_state, data) => ({ data: data.costEstimator }))
);
