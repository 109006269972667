export enum SpecialUrls {
  SEEResultPage = '/bilan-energetique/resultat',
}

export enum GaModalPages {
  GaConfirmation = '/confirmation-simulation',
  GaPhoneModal = '/utilisateur-telephone/popin',
  GaCreationModal = '/utilisateur-creation/popin',
  GaConnectionModal = '/utilisateur-connexion/popin',
  GaSEEInterest = '/bilan-energetique/resultat/utilisateur-identite',
  GaSEECreationUser = '/bilan-energetique/resultat/creation-utilisateur',
  GaNeedHelpModal = '/confirmation-prime-seule-artisan/rappel-commerce',
}

export enum GTMPropertiesNames {
  GaEventCategory = 'ga-event-category',
  GaEventAction = 'ga-event-action',
  GaEventLabel = 'ga-event-label',
  GaEventPage = 'ga-event-page',
  GaEventValue = 'ga-event-value',
  GaPageName = 'ga-page-name',
  // from solaire
  GaEvent = 'event',
}

export enum GTMEventActionValues {
  FORM_VALIDATION = 'form-validation',
  CALLBACK_VALIDATION = 'callback-validation',
  CLICK_TO_CALL = 'clic-to-call',
  DISPLAY_TO_CALL = 'display-to-call',
  OPT_OUT = 'opt-out',
  RDV_TEL = 'rdv-tel',
  DONT_CALL_BACK = 'ne-pas-rappeler',
  DONT_CALL_BACK_CONFIRM = 'ne-pas-rappeler-confirmation',
  RDV_TEL_IMMEDIATELY = 'rdv-tel-immediat',
  FUNDING_INTEREST = 'financement-interet',
  INSERT_BAD_VERIFICATION_CODE = 'form_mauvais_code_selfcare',
  SEE_INTEREST = 'see_interest_part',
  SEE_VALIDATION = 'see_validation',
  SEE_CONNECT = 'see_se_connecter',
  SEE_OPT_OUT_1 = 'form_opt_out_see_1',
  SEE_OPT_OUT_2 = 'form_opt_out_see_2',
  FORM_SEE = 'form-see',
  FORM_SERENITY = 'form-serenite',
}

export enum GTMEventCategoryValues {
  FORM_PRIME = 'form-travaux-aides',
  FORM_1EURO_GENERIC = 'form-isolation-multitravaux',
  FORM_HEATING = 'form-chauffage',
  FORM_ENERGETIC_AUDIT = 'form-audit-energetique',
  OFFER_FUNDING = 'offre-financement',
  PARCOURS_SOLAIRE = 'solar',
  // Parcours Legacy
  GENERIC_INSULATION = 'isolation',
  HEATING_PUMP = 'pompe-a-chaleur',
  HELPS_AND_WORKS = 'travaux-aides',
  ENERGETIC_AUDIT = 'audit-energetique',
  SELFCARE_MER_NPR_RECAP = 'prime-seule-artisan-relance',
}

export enum GTMEventLabelValues {
  ELIGIBLE_SERENITY_INSULATION = 'eligible-serenite-isolation',
  ELIGIBLE_SERENITY_HEATING = 'eligible-serenite-chauffage',
  ELIGIBLE_LIBERTY_CONFIRMATION = 'eligible-liberte',
  NON_ELIGIBLE_CONFIRMATION = 'non-eligible',
  CONFIRMATION_WITHOUT_APPOINTMENT = 'confirmation-rappel-sansrdv',
  TOGGLE_FUNDING = 'toggle-financement',
}

export enum GTMEventPageValues {
  ALREADY_CONNECTED_SHOW_MODAL = '/popin/verification-identite',
  ALREADY_CONNECTED_DISMISS_MODAL = '/popin/verification-identite-sortie',
  GET_SELFCARE_PRIME_FAILED = '/prime-seule/recapitulatif/erreur-creation-dossier',
  REPUTATION_SHOW_MODAL = '/popin/confirmation-reputation',
  CHECK_PHONE_NUMBER_SHOW_MODAL = '/popin/verification-numero',
}

export enum GTMEventsTypes {
  INPUT_FORM = 'saisie-form',
  PISTE_CREATION = 'creation-piste',
  PROJECT = 'WorkProject',
  BONUS_ESTIMATION = 'bonusEstimation',
  PAGE_VIEW = 'pageView',
  EVENT_CLICK = 'event-clic',
  FORM_VALIDATION = 'form-validation',
  FORM_SOLAIRE = 'form-solaire',
  WORK_SOLAR = 'photovoltaic_panel',
}
