import { Injectable } from '@angular/core';

import { HouseholdIncomeService } from '@prc-lib-core';
import { AnswserKeys } from '@prc-serenity-models/keys.model';
import { AnswerService } from '@prc-serenity-services/answer/answer.service';
import { HouseholdIncomePrecarityLevelAnahApiResponse } from '@prc-lib-core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HouseholdIncomeResolver {
  constructor(private readonly answerService: AnswerService, private readonly householdIncomeService: HouseholdIncomeService) {}

  resolve(): Observable<HouseholdIncomePrecarityLevelAnahApiResponse> {
    const zipCode = this.answerService.getZipCode();
    const householdComposition = this.answerService.getAnswerFromLocalStorage<number>(AnswserKeys.HouseholdComposition);

    return this.householdIncomeService.getAnahPrecarityLevels$(householdComposition, zipCode);
  }
}
