<prc-lib-header
  id="header"
  [title]="title$ | async"
  [isDisplayingClickInfo]="!!(displayLegalMentionsOnMobile$ | async)"
  (clickInfo)="onClickInfo()"
  [partnerLogoSrc]="partnerLogoSrc"
  [partnerLink]="partnerLink"
  [isDisplayReview]="false"
  [isDesktop]="(isDesktop$ | async) === true"
  [partnerLogoReverse]="partnerLogoReverse"
  [headerLogoSeparatorLabel]="headerLogoSeparatorLabel"
  [sidebarStatusList]="sidebarStatusList$ | async"
></prc-lib-header>

<div
  *ngIf="{ shouldDisplay: displaySidebar$ | async } as sidebar"
  class="flex flex-grow w-full overflow-hidden"
>
  <div *ngIf="sidebar.shouldDisplay" class="flex">
    <div *ngIf="isDesktop$ | async" class="w-[296px] p-8 bg-white flex flex-col gap-4">
      @if (displayReviewsSidebar$ | async) {
        <div class="w-[232px] h-[135px] my-auto flex items-center justify-center rounded-[8px] bg-cold outline outline-1 outline-border-card">
          <prc-lib-trustpilot-micro-review class="block px-[30px]"></prc-lib-trustpilot-micro-review>
        </div>
      } @else {
        <prc-lib-sidebar-status
          *ngIf="sidebarStatusList$ | async as sidebarList"
          [list]="sidebarList"
          [progress]="(progressInPercent$ | async) || 0"
          class="mb-auto"
        />
      }
      <p class="text-sm text-article-blue">* {{ legalMentionsLabel }}</p>
    </div>
  </div>

  <div class="grow overflow-auto flex flex-col">
    <div class="sticky top-0 z-[1] sticky-mask">
      <effy-progress-bar *ngIf="displayProgressBar$ | async" class="flex" [size]="progressBarSize" [value]="(progressInPercent$ | async) || 0" />
      @if (displayBackButton$ | async) {
        <prc-lib-back-button class="block my-8 mx-8 relative"></prc-lib-back-button>
      }
    </div>
    
    <ng-content select="[router-outlet]"></ng-content>
  </div>
</div>
