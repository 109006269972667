import { createReducer, on } from '@ngrx/store';
import { Nullable } from '../../types/nullable.type';
import {
  EFFY_FETCH_INTENTIONALITY_ACTION,
  EFFY_FETCH_INTENTIONALITY_FAILED_ACTION,
  EFFY_FETCH_INTENTIONALITY_SUCCESS_ACTION,
} from '../actions/intentionality.action';

export interface EffyIntentionalityDataState {
  hasCampaign: boolean;
  hasIntentionalityRequired: boolean;
  hasPromotedOptOut: boolean;
  phoneNumberCampaign: Nullable<string>;
  isAcceptingSelfcare: boolean;
}

export interface EffyIntentionalityState {
  data: EffyIntentionalityDataState;
  isLoaded: boolean;
}

export const effyIntentionalityInitialState: EffyIntentionalityState = {
  data: {
    hasCampaign: true,
    hasIntentionalityRequired: false,
    hasPromotedOptOut: false,
    phoneNumberCampaign: null,
    isAcceptingSelfcare: false,
  },
  isLoaded: false,
};

export type EffyIntentionalityKeyType = keyof EffyIntentionalityState;

export const effyIntentionalityReducer = createReducer(
  effyIntentionalityInitialState,
  on(EFFY_FETCH_INTENTIONALITY_ACTION, () => effyIntentionalityInitialState),
  on(EFFY_FETCH_INTENTIONALITY_SUCCESS_ACTION, (_state, data) => ({ data: data.results, isLoaded: true })),
  on(EFFY_FETCH_INTENTIONALITY_FAILED_ACTION, () => ({ data: effyIntentionalityInitialState.data, isLoaded: true }))
);
