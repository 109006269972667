import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { EffyIcons } from '@effy-tech/angular-common';

@Component({
  selector: 'prc-lib-logo',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() linkUrl = '/';
  @Input() partnerLogoSrc = '';
  @Input() partnerLink = '';
  @Input() partnerLogoReverse = false;
  @Input() headerLogoSeparatorLabel = 'et';

  readonly logo = EffyIcons.EFFY_BASE_LOGO
}
