import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { SidebarStatusList } from '../../../models/sidebar-status.model';
import { EffyProgressBarComponent, EffyProgressBarSize } from '@effy-tech/angular-common';

@Component({
  selector: 'prc-lib-sidebar-status',
  standalone: true,
  imports: [CommonModule, EffyProgressBarComponent],
  templateUrl: './sidebar-status.component.html',
  styleUrls: ['./sidebar-status.component.scss'],
})
export class SidebarStatusComponent {
  readonly progress = input<number>(0);
  readonly list = input<SidebarStatusList>([]);
  
  readonly progressBarSize = EffyProgressBarSize.SMALL;
}
