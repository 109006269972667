import { Routes } from "@angular/router";
import { StepsPaths } from "@prc-serenity-configs/paths.config";

export const HousingEnergyRoutes: Routes = [
  {
    path: StepsPaths.HOUSING_ENERGY_OIL,
  loadComponent: () =>
    import('../../components/pages/housing/housing-energy/oil/oil.component').then(
      component => component.HousingEnergyOilComponent
    ),
},
{
  path: StepsPaths.HOUSING_ENERGY_ELECTRIC,
  loadComponent: () =>
    import('../../components/pages/housing/housing-energy/electric/electric.component').then(
      component => component.HousingEnergyElectricComponent
    ),
},
{
  path: StepsPaths.HOUSING_ENERGY_GAS,
  loadComponent: () =>
    import('../../components/pages/housing/housing-energy/gas/gas.component').then(
      component => component.HousingEnergyGasComponent
    ),
},
]
