import { Injectable, inject } from '@angular/core';
import { CookiesGa } from '../../configs';
import { UtmModel } from '../../models';
import { UtmUtil } from '../../utils/utm.util';
import { CookieManagerService } from '../cookie-manager/cookie-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UtmService {
  private readonly cookieManagerService = inject(CookieManagerService);

  getData(): UtmModel {
    return UtmUtil.getUtmFromCookie(this.cookieManagerService.getCookie(CookiesGa.utm));
  }
}
