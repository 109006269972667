import { EffyEnvironment } from '@effy-tech/angular-common';

export const getBaseUrlDataEnrichment = (effyEnv: EffyEnvironment): string => {
  const urlByEnvironment: { [key in EffyEnvironment]: string } = {
    [EffyEnvironment.Dev]: 'https://user-enrichment.dev.api.quelleenergie.net',
    [EffyEnvironment.Rct]: 'https://user-enrichment.rct.api.quelleenergie.net',
    [EffyEnvironment.Prod]: 'https://user-enrichment.api.effy.fr',
  };

  return `${urlByEnvironment[effyEnv]}/v1/enrichment`;
};

export const getDataEnrichmentWs = (effyEnv: EffyEnvironment, apiKey: string): string => {
  const enrichmentUrl = new URL(getBaseUrlDataEnrichment(effyEnv));
  enrichmentUrl.searchParams.append('key', apiKey);

  return enrichmentUrl.toString();
};
