import { Injectable } from '@angular/core';
import { Sites } from '@prc-serenity-configs/sites.config';
import { LeclercService } from '../services/sites/leclerc.service';

@Injectable()
export class SiteServiceFactory {
  getSiteService(site: Sites | undefined) {
    switch (site) {
      case Sites.LECLERC:
        return new LeclercService();
      default:
        throw new Error(`Unsupported site : ${site}`);
    }
  }
}
