import { Sites } from '@prc-serenity-configs/sites.config';
import { environment } from '../../../environments/environment';

export class SiteUtil {
  static readonly ALL_SITES: Sites[] = Object.values(Sites);

  static computeSiteLogo(site: Sites): string {
    return `${environment.commonAssetsUrl}/images/logos/partners/${site}/logo_${site}.svg`;
  }
}
