import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { RouterOutlet } from '@angular/router';
import { Theme, setTheme } from '@effy-tech/design-system';
import { Store } from '@ngrx/store';
import {
  LayoutComponent,
  MediaQueryService,
  Nullable,
  PARTNER_ITEM_NAME,
  ProgressLine,
  QueryParamsService,
  SidebarStatusList,
} from '@prc-lib-core';
import { AppState } from '@prc-serenity-store/app-state.model';
import { getLayoutState } from '@prc-serenity-store/selectors/layout.selector';
import { Observable, delay, filter, map, of, take } from 'rxjs';
import { ModalLegalMentionsComponent } from './components/modal-legal-mentions/modal-legal-mentions.component';
import { SiteService } from './core/services/sites/site.service';
import { LEGAL_MENTIONS_LABEL } from './labels/legal-mentions.label';
import { EffyIconsService } from '@effy-tech/angular-common';

@Component({
  selector: 'prc-serenity-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, LayoutComponent],
})
export class AppComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  readonly legalMentionsLabel = LEGAL_MENTIONS_LABEL;
  readonly isDesktop$: Observable<boolean> = this.mediaQueryService.isDesktop$;
  readonly queryParamsService = inject(QueryParamsService);
  layout$ = this.store.select(getLayoutState);
  progressInPercent$: Observable<number> = of(0);
  sidebarStatusList$!: Observable<SidebarStatusList>;
  displaySidebar$!: Observable<Nullable<boolean>>;
  displayReviewsSidebar$!: Observable<Nullable<boolean> | undefined>;
  displayProgressBar$!: Observable<Nullable<boolean>>;
  displayBackButton$!: Observable<Nullable<boolean>>;
  displayLegalMentionsOnMobile$!: Observable<Nullable<boolean>>;
  displayTitleOnHeader$!: Observable<Nullable<boolean>>;
  title$!: Observable<string>;
  partnerLogoSrc = this.siteService.logo;
  partnerLogoReverse = this.siteService.partnerLogoReverse;
  partnerLink = this.siteService.link;
  headerLogoSeparatorLabel = this.siteService.headerLogoSeparatorLabel;

  constructor(
    private readonly store: Store<AppState>,
    private readonly mediaQueryService: MediaQueryService,
    private readonly matDialog: MatDialog,
    private readonly siteService: SiteService,
    private readonly iconsService: EffyIconsService
  ) {
  }

  ngOnInit(): void {
    this.progressInPercent$ = this.layout$.pipe(
      delay(0),
      map(layout => ProgressLine.getProgresslineLength({ totalSteps: this.siteService.totalSteps, ...layout.progress }))
    );
    this.sidebarStatusList$ = this.layout$.pipe(map(layout => layout.sidebarStatus));
    this.displayProgressBar$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displayProgressBar)
    );
    this.displayBackButton$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displayBackButton)
    );
    this.displaySidebar$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displaySidebar)
    );
    this.displayReviewsSidebar$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displayReviews)
    );
    this.displayProgressBar$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displayProgressBar)
    );
    this.displayLegalMentionsOnMobile$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displayLegalMentionsOnMobile)
    );
    this.displayTitleOnHeader$ = this.layout$.pipe(
      delay(0),
      map(layout => layout.displayTitleOnHeader)
    );
    this.title$ = this.displayTitleOnHeader$.pipe(
      map(isDisplayingTitle => (isDisplayingTitle ? 'Réalisez un bilan énergétique gratuit en 5 minutes*' : '')),
      takeUntilDestroyed(this.destroyRef)
    );

    this.setPartnerCookie();
    setTheme(Theme.EFFY);
    this.iconsService.init();
  }

  onClickInfo(): void {
    this.matDialog.open(ModalLegalMentionsComponent, {
      autoFocus: false,
    });
  }

  private setPartnerCookie(): void {
    this.queryParamsService
      .getQueryParams(PARTNER_ITEM_NAME)
      .pipe(
        filter(partnerName => partnerName !== undefined),
        take(1)
      )
      .subscribe(partnerName => {
        sessionStorage.setItem(PARTNER_ITEM_NAME, partnerName);
      });
  }
}
