import { createAction, props } from '@ngrx/store';
import { CostEstimatorOfferWithOfferWorkType } from '@prc-lib-core';

export enum ResultsAction {
  Estimate = '[Results] Estimate',
  EstimateSuccess = '[Results] Estimate Success',
  EstimateFailed = '[Results] Estimate Failed',
}

export const RESULTS_ESTIMATE_ACTION = createAction(ResultsAction.Estimate);
export const RESULTS_ESTIMATE_SUCCESS_ACTION = createAction(
  ResultsAction.EstimateSuccess,
  props<{ costEstimator: CostEstimatorOfferWithOfferWorkType[] }>()
);
export const RESULTS_ESTIMATE_FAILED_ACTION = createAction(ResultsAction.EstimateFailed);
