import { Injectable } from '@angular/core';
import { LeadIntentionalityResponseModel, LeadsService } from '@effy-tech/angular-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap, timeout } from 'rxjs';
import {
  EFFY_FETCH_INTENTIONALITY_FAILED_ACTION,
  EFFY_FETCH_INTENTIONALITY_SUCCESS_ACTION,
  EffyIntentionalityAction,
} from '../actions/intentionality.action';
import { EffyIntentionalityDataState } from '../reducers/intentionality.reducer';

@Injectable()
export class EffyIntentionalityEffects {
  fetchEffyIntentionality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EffyIntentionalityAction.FetchIntentionality),
      switchMap(({ request }) => this.leadsService.intentionality(request).pipe(timeout(5000))),
      mergeMap(result => [
        EFFY_FETCH_INTENTIONALITY_SUCCESS_ACTION({ results: this.mapLeadIntentionalityResponseToEffyIntentionalityState(result) }),
      ]),
      catchError(() => of(EFFY_FETCH_INTENTIONALITY_FAILED_ACTION()))
    )
  );

  constructor(private readonly actions$: Actions, private readonly leadsService: LeadsService) {}

  private mapLeadIntentionalityResponseToEffyIntentionalityState(response: LeadIntentionalityResponseModel): EffyIntentionalityDataState {
    return {
      hasCampaign: response.campagne,
      hasIntentionalityRequired: response.intentionnalite_requise,
      hasPromotedOptOut: response.mise_en_avant_optout,
      phoneNumberCampaign: response.numero_telephone_campagne,
      isAcceptingSelfcare: response.accepte_selfcare,
    };
  }
}
