import { Injectable } from '@angular/core';
import { GoogleTagManager, GTMEvent, GTMEventPageStepNumber, ReputationValue, WindowExtended } from '../../models';
import {
  GaModalPages,
  GTMEventActionValues,
  GTMEventCategoryValues,
  GTMEventLabelValues,
  GTMEventPageValues,
  GTMEventsTypes,
  GTMPropertiesNames,
} from '../../values';

declare let window: WindowExtended;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService implements GoogleTagManager {
  pushEvent(dataSet: GTMEvent): void {
    if (window.dataLayer) {
      window.dataLayer.push(dataSet);
    }
  }

  pushEventCallbackValidation<T extends string>(category: T): void {
    this.pushEvent({
      event: GTMEventsTypes.INPUT_FORM,
      [GTMPropertiesNames.GaEventAction]: GTMEventActionValues.CALLBACK_VALIDATION,
      [GTMPropertiesNames.GaEventCategory]: category,
    });
  }

  pushEventCreateLead(pisteId: string, gaEventCategory: string, gaEventLabel: string = '', gaEventValue?: number): void {
    this.pushEvent({
      event: GTMEventsTypes.PISTE_CREATION,
      [GTMPropertiesNames.GaEventLabel]: gaEventLabel,
      [GTMPropertiesNames.GaEventAction]: GTMEventActionValues.FORM_VALIDATION,
      [GTMPropertiesNames.GaEventCategory]: gaEventCategory,
      [GTMPropertiesNames.GaEventValue]: gaEventValue,
      pisteId,
    });
  }

  pushEventAction(gaEventAction: GTMEventActionValues): void {
    this.pushEvent({
      event: GTMEventsTypes.EVENT_CLICK,
      [GTMPropertiesNames.GaEventAction]: gaEventAction,
    });
  }

  pushEventAfterAlreadyConnectedModalClosed(): void {
    this.pushEvent({
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: GTMEventPageValues.ALREADY_CONNECTED_DISMISS_MODAL,
    });
  }

  pushEventAppointment<T extends string>(category: T, immediate?: boolean): void {
    this.pushEvent({
      event: GTMEventsTypes.INPUT_FORM,
      [GTMPropertiesNames.GaEventAction]: immediate ? GTMEventActionValues.RDV_TEL_IMMEDIATELY : GTMEventActionValues.RDV_TEL,
      [GTMPropertiesNames.GaEventCategory]: category,
    });
  }

  pushEventCreateProjectSimplify(): void {
    this.pushEvent({
      event: GTMEventsTypes.PISTE_CREATION,
      [GTMPropertiesNames.GaEventAction]: GTMEventActionValues.FORM_VALIDATION,
      [GTMPropertiesNames.GaEventCategory]: GTMEventCategoryValues.FORM_ENERGETIC_AUDIT,
    });
  }

  pushEventPageView(gaPage: GaModalPages, path: string = ''): void {
    const pageViewEvent: GTMEvent = {
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: path + gaPage,
    };
    this.pushEvent(pageViewEvent);
  }

  pushEventPageViewProjectSimplify(gtmEventPageStepNumber: GTMEventPageStepNumber): void {
    this.pushEvent({
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventCategory]: GTMEventCategoryValues.FORM_ENERGETIC_AUDIT,
      [GTMPropertiesNames.GaEventPage]: gtmEventPageStepNumber[GTMPropertiesNames.GaEventPage],
      stepNumber: gtmEventPageStepNumber.stepNumber,
    });
  }

  pushEventReputationModalAnswer(reputationAnswer: ReputationValue): void {
    this.pushEvent({
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: `${GTMEventPageValues.REPUTATION_SHOW_MODAL}/${reputationAnswer}`,
    });
  }

  pushEventReputationModalDisplay(): void {
    this.pushEvent({
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: GTMEventPageValues.REPUTATION_SHOW_MODAL,
    });
  }

  pushEventUserCampaignCall<T extends string>(category: T, campaignGtmCallValue: GTMEventActionValues): void {
    this.pushEvent({
      event: GTMEventsTypes.EVENT_CLICK,
      [GTMPropertiesNames.GaEventAction]: campaignGtmCallValue,
      [GTMPropertiesNames.GaEventCategory]: category,
    });
  }

  pushEventUserHasInsertBadCodeInCheckPhoneNumberModal<T extends string>(category: T): void {
    this.pushEvent({
      event: GTMEventsTypes.INPUT_FORM,
      [GTMPropertiesNames.GaEventAction]: GTMEventActionValues.INSERT_BAD_VERIFICATION_CODE,
      [GTMPropertiesNames.GaEventCategory]: category,
    });
  }

  pushEventUserWasShownAlreadyConnectedModal(): void {
    this.pushEvent({
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: GTMEventPageValues.ALREADY_CONNECTED_SHOW_MODAL,
    });
  }

  pushEventUserWasShownCheckPhoneNumberModal(): void {
    this.pushEvent({
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: GTMEventPageValues.CHECK_PHONE_NUMBER_SHOW_MODAL,
    });
  }

  pushEventOptOut<T extends string>(category: T): void {
    this.pushEvent({
      event: GTMEventsTypes.INPUT_FORM,
      [GTMPropertiesNames.GaEventAction]: GTMEventActionValues.OPT_OUT,
      [GTMPropertiesNames.GaEventCategory]: category,
    });
  }

  pushEventFundingInterest(): void {
    this.pushEvent({
      event: GTMEventsTypes.EVENT_CLICK,
      [GTMPropertiesNames.GaEventAction]: GTMEventActionValues.FUNDING_INTEREST,
      [GTMPropertiesNames.GaEventCategory]: GTMEventCategoryValues.OFFER_FUNDING,
      [GTMPropertiesNames.GaEventLabel]: GTMEventLabelValues.TOGGLE_FUNDING,
    });
  }
}
