export enum AbTastyKeys {
  CampaignName = 'campaignName',
  CampaignId = 'campaignId',
  CampaignChildrenIds = 'campaignChildrenIds',
  CampaignStatus = 'campaignStatus',
  VariationName = 'variationName',
  VariationId = 'variationId',
  VariationMasterId = 'variationMasterId',
  AccountId = 'accountId',
  AccountName = 'accountName',
}

export interface AbTastyDataModel {
  [AbTastyKeys.AccountId]: number;
  [AbTastyKeys.AccountName]: string;
  [AbTastyKeys.CampaignId]: number;
  [AbTastyKeys.CampaignName]: string;
  [AbTastyKeys.CampaignChildrenIds]: number[];
  [AbTastyKeys.CampaignStatus]: string;
  [AbTastyKeys.VariationId]: number;
  [AbTastyKeys.VariationName]: string;
  [AbTastyKeys.VariationMasterId]: number;
}

export interface AbTastyCampaignWindow {
  [key: number]: string;
}

interface AbTastyVariationsWindow {
  [key: number]: { [key: string]: unknown; id?: number; name?: string; masterVariationId?: number };
}

/**
 * Be carreful with ABTasty object
 * Structure can change over time
 */
export interface AbTastyWindow {
  getCampaignHistory?: () => AbTastyCampaignWindow;
  getAccountSettings?: () => {
    id?: number;
    identifier?: string;
    accountName?: string;
  };
  accountData?: {
    accountSettings?: unknown;
    tests?: {
      [key: number]: {
        [key: string]: unknown | AbTastyVariationsWindow;
        id?: number;
        name?: string;
        type?: string;
        status?: string;
        children?: number[];
        variations?: AbTastyVariationsWindow;
      };
    };
  };
}
