import { Injectable } from '@angular/core';
import { WindowExtended } from '../../models';
import { AbTastyDataModel, AbTastyKeys } from '../../models/abtasty.model';

@Injectable({
  providedIn: 'root',
})
export class AbTastyService {
  getData(): AbTastyDataModel | null {
    try {
      const abTasty = (window as unknown as WindowExtended)?.ABTasty;

      if (abTasty) {
        const tests = abTasty?.accountData?.tests;
        const campaign = abTasty?.getCampaignHistory?.() ? Object.entries(abTasty.getCampaignHistory())[0] : null;

        const campaignId = !isNaN(Number(campaign?.[0])) ? Number(campaign?.[0]) : undefined;
        const variationId = !isNaN(Number(campaign?.[1])) ? Number(campaign?.[1]) : undefined;

        const accountSettings = abTasty?.getAccountSettings?.();
        const accountId = accountSettings?.id;
        const accountName = accountSettings?.accountName;

        const campaignDetails = campaignId ? tests?.[campaignId] : null;
        const variationDetails = variationId ? campaignDetails?.variations?.[variationId] : null;

        return {
          [AbTastyKeys.CampaignName]: campaignDetails?.name,
          [AbTastyKeys.CampaignId]: campaignId,
          [AbTastyKeys.CampaignStatus]: campaignDetails?.status,
          [AbTastyKeys.CampaignChildrenIds]: campaignDetails?.children,
          [AbTastyKeys.VariationName]: variationDetails?.name,
          [AbTastyKeys.VariationId]: variationId,
          [AbTastyKeys.VariationMasterId]: variationDetails?.masterVariationId,
          [AbTastyKeys.AccountId]: accountId,
          [AbTastyKeys.AccountName]: accountName,
        } as AbTastyDataModel;
      }
    } catch {
      return null;
    }

    return null;
  }
}
