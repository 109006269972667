import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResultsService } from '@prc-serenity-services/results/results.service';
import {
  RESULTS_ESTIMATE_ACTION,
  RESULTS_ESTIMATE_FAILED_ACTION,
  RESULTS_ESTIMATE_SUCCESS_ACTION,
} from '@prc-serenity-store/actions/results.action';
import { catchError, mergeMap, of } from 'rxjs';

@Injectable()
export class ResultsEffects {
  estimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RESULTS_ESTIMATE_ACTION),
      mergeMap(() => this.resultsService.estimate$()),
      mergeMap(estimate =>
        estimate ? [RESULTS_ESTIMATE_SUCCESS_ACTION({ costEstimator: [estimate] })] : [RESULTS_ESTIMATE_FAILED_ACTION()]
      ),
      catchError(() => of(RESULTS_ESTIMATE_FAILED_ACTION()))
    )
  );

  constructor(private readonly actions$: Actions, private readonly resultsService: ResultsService) {}
}
