import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effyIntentionalityReducer } from '@prc-lib-core';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../../environments/environment';
import { StoreKeys } from '../configs/store.config';
import { answersWorksInitialState, answersWorksReducer } from './reducers/answers-works.reducer';
import { answersInitialState, answersReducer } from './reducers/answers.reducer';
import { layoutInitialState, layoutReducer } from './reducers/layout.reducer';
import { resultsInitialState, resultsReducer } from './reducers/results.reducer';
import { userInitialState, userReducer } from './reducers/user.reducer';

export const reducers = {
  [StoreKeys.Answers]: answersReducer,
  [StoreKeys.AnswersWorks]: answersWorksReducer,
  [StoreKeys.Layout]: layoutReducer,
  [StoreKeys.User]: userReducer,
  [StoreKeys.Results]: resultsReducer,
  [StoreKeys.Intentionality]: effyIntentionalityReducer,
};

function localStorageSyncReducer(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return localStorageSync({
    keys: [
      { [StoreKeys.Answers]: Object.keys(answersInitialState) },
      { [StoreKeys.Layout]: Object.keys(layoutInitialState) },
      { [StoreKeys.User]: Object.keys(userInitialState) },
      { [StoreKeys.AnswersWorks]: Object.keys(answersWorksInitialState) },
      { [StoreKeys.Results]: Object.keys(resultsInitialState) },
    ],
    storageKeySerializer: key => `prc-serenity-${key}`,
    rehydrate: true,
  })(reducer);
}

const metaReducers: Array<MetaReducer<unknown, Action>> = [localStorageSyncReducer];

export const reducersConfig = {
  metaReducers,
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
  },
};

export const storeDevtoolsModule = environment.production ? [] : StoreDevtoolsModule.instrument({connectInZone: true});
