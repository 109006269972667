import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieManagerService, CookiesToken } from '@prc-lib-core';
import { Observable } from 'rxjs';
import { WS_REQUIRING_SESSION_TOKEN } from '../../configs/ws.config';

@Injectable()
export class SessionTokenInterceptor implements HttpInterceptor {
  constructor(private cookieManagerService: CookieManagerService) {}

  intercept(req: HttpRequest<Record<string, unknown>>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const IS_REQUIRING_SESSION_TOKEN =
      WS_REQUIRING_SESSION_TOKEN.includes(req.url) || WS_REQUIRING_SESSION_TOKEN.some(u => req.url.indexOf(u) > -1);
    if (IS_REQUIRING_SESSION_TOKEN) {
      return this.injectSessionToken(req, next);
    }
    return next.handle(req);
  }

  private injectSessionToken(
    request: HttpRequest<Record<string, unknown>>,
    next: HttpHandler
  ): Observable<HttpEvent<Record<string, unknown>>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.cookieManagerService.getCookie(CookiesToken.sessionToken)}`,
      },
    });
    return next.handle(request);
  }
}
