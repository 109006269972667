<nav>
  <p class="text-lg font-700 mb-[min(40px,3vh)]">Votre parcours</p>
  <div class="flex items-stretch gap-4">
    <effy-progress-bar [size]="progressBarSize" [vertical]="true" [value]="progress()" class="!h-auto" />
    <ol class="flex flex-col justify-between min-h-[170px] gap-8">
      @for (status of list(); track $index) {
        <li class="font-300 text-sm" [ngClass]="{ 'font-600': status.isActive }">
          <span class="self-center">{{ status.title }}</span>
        </li>
      }
    </ol>
  </div>
</nav>
