import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HeaderComponent } from '../';
import { TrustpilotMicroReviewComponent } from '../trustpilot-widget/trustpilot-micro-review/trustpilot-micro-review.component';
import { SidebarStatusList } from '../../models';
import { Nullable } from '../../types';
import { BackButtonComponent, SidebarStatusComponent, SidebarStatusMobileComponent } from '../ui';
import { EffyProgressBarComponent, EffyProgressBarSize } from '@effy-tech/angular-common';

@Component({
  selector: 'prc-lib-layout',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    SidebarStatusComponent,
    SidebarStatusMobileComponent,
    EffyProgressBarComponent,
    BackButtonComponent,
    TrustpilotMicroReviewComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input() displayLegalMentionsOnMobile$!: Observable<Nullable<boolean>>;
  @Input() title$!: Observable<string>;
  @Input() displayBackButton$!: Observable<Nullable<boolean>>;
  @Input() displaySidebar$!: Observable<Nullable<boolean>>;
  @Input() isDesktop$!: Observable<boolean>;
  @Input() sidebarStatusList$!: Observable<SidebarStatusList>;
  @Input() legalMentionsLabel = '';
  @Input() displayProgressBar$!: Observable<Nullable<boolean>>;
  @Input() displayReviewsSidebar$!: Observable<Nullable<boolean> | undefined>;
  @Input() progressInPercent$: Observable<number> = of(0);
  @Input() partnerLogoSrc = '';
  @Input() partnerLink = '';
  @Input() partnerLogoReverse = false;
  @Input() headerLogoSeparatorLabel!: string;

  @Output() clickInfo = new EventEmitter<void>();

  readonly progressBarSize = EffyProgressBarSize.SMALL;

  onClickInfo(): void {
    this.clickInfo.emit();
  }
}
