import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { WS_REQUIRING_API_KEY } from '../configs/ws.config';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<Record<string, unknown>>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (WS_REQUIRING_API_KEY.includes(request.url)) {
      return this.injectApiToken(request, next);
    }

    return next.handle(request);
  }

  private injectApiToken(request: HttpRequest<Record<string, unknown>>, next: HttpHandler): Observable<HttpEvent<Record<string, unknown>>> {
    request = request.clone({
      setParams: {
        key: environment.apiKey,
      },
    });

    return next.handle(request);
  }
}
