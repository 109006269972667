import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StepsPaths } from './core/configs/paths.config';
import { HouseholdIncomeResolver } from './core/resolvers/household-income.resolver';
import { HousingEnergyRoutes } from './core/routes/housing-energy.routes';
import { WorksQuestionsRoutes } from './core/routes/works-questions.routes';

const firstRoute = StepsPaths.HOUSING_TYPE;

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: firstRoute,
  },
  {
    path: StepsPaths.NONE_ELIGIBLE,
    pathMatch: 'full',
    loadComponent: () => import('./components/none-eligible/none-eligible.component').then(component => component.NoneEligibleComponent),
  },
  {
    path: StepsPaths.HOUSING_TYPE,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/housing/housing-type/housing-type.component').then(component => component.HousingTypeComponent),
  },
  {
    path: StepsPaths.HOUSING_CONSTRUCTION,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/housing/housing-construction/housing-construction.component').then(
        component => component.HousingConstructionComponent
      ),
  },
  {
    path: StepsPaths.HOUSING_SURFACE,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/housing/housing-surface/housing-surface.component').then(component => component.HousingSurfaceComponent),
  },
  {
    path: StepsPaths.HOUSING_ENERGY,
    loadComponent: () =>
      import('./components/pages/housing/housing-energy/housing-energy.component').then(component => component.HousingEnergyComponent),
  },
  ...HousingEnergyRoutes,
  {
    path: StepsPaths.WORKS,
    pathMatch: 'full',
    loadComponent: () => import('./components/pages/works/works.component').then(component => component.WorksComponent),
  },
  {
    path: StepsPaths.WORK_ADDRESS,
    loadComponent: () =>
      import('./components/pages/works-address/works-address.component').then(component => component.WorksAddressComponent),
  },
  ...WorksQuestionsRoutes,
  {
    path: StepsPaths.PROJECT_STATUS,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/project-status/project-status.component').then(component => component.ProjectStatusComponent),
  },
  {
    path: StepsPaths.TIMING_PROJECT,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/timing-project/timing-project.component').then(component => component.TimingProjectComponent),
  },
  {
    path: StepsPaths.USER_STATUS,
    pathMatch: 'full',
    loadComponent: () => import('./components/pages/user-status/user-status.component').then(component => component.UserStatusComponent),
  },
  {
    path: StepsPaths.HOUSING_ZIP_CODE,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/housing-zip-code/housing-zip-code.component').then(component => component.HousingZipCodeComponent),
  },
  {
    path: StepsPaths.HOUSEHOLD_COMPOSITION,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/household-composition/household-composition.component').then(
        component => component.HouseholdCompositionComponent
      ),
  },
  {
    path: StepsPaths.HOUSEHOLD_INCOME,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/household-income/household-income.component').then(component => component.HouseholdIncomeComponent),
    resolve: {
      precarityLevels: HouseholdIncomeResolver,
    },
  },
  {
    path: StepsPaths.USER_IDENTITY,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/user-identity/user-identity.component').then(component => component.UserIdentityComponent),
  },
  {
    path: StepsPaths.USER_PHONE,
    pathMatch: 'full',
    loadComponent: () => import('./components/pages/user-phone/user-phone.component').then(component => component.UserPhoneComponent),
  },
  {
    path: StepsPaths.USER_CREATION,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/user-register/user-register.component').then(component => component.UserRegisterComponent),
  },
  {
    path: StepsPaths.USER_LOGIN,
    pathMatch: 'full',
    loadComponent: () => import('./components/pages/user-login/user-login.component').then(component => component.UserLoginComponent),
  },
  {
    path: StepsPaths.CONFIRMATION,
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/pages/confirmation/confirmation.component').then(component => component.ConfirmationComponent),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top',
    }),
    CommonModule,
  ],
})
export class AppRoutingModule {}
