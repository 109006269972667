import { APP_BASE_HREF } from '@angular/common';
import { LOCALE_ID, makeEnvironmentProviders } from '@angular/core';
import { API_KEY, EFFY_ENVIRONMENT, EffyEnvironment } from '@effy-tech/angular-common';
import { provideLottieOptions } from 'ngx-lottie';
import { SPONSOR_CODE_CONFIG } from '../features';

export function provideParcoursConfig(config: {
  env: EffyEnvironment;
  apiKey: string;
  baseHref: string;
  featureFlags: { sponsorCodeActive: boolean };
}) {
  return makeEnvironmentProviders([
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: EFFY_ENVIRONMENT, useValue: config.env },
    { provide: API_KEY, useValue: config.apiKey },
    { provide: APP_BASE_HREF, useValue: config.baseHref },
    { provide: SPONSOR_CODE_CONFIG, useValue: { isActive: config.featureFlags.sponsorCodeActive } },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ]);
}
