import { createReducer, on } from '@ngrx/store';
import { Nullable, SidebarStatusList } from '@prc-lib-core';
import { SidebarId, SIDEBAR_STATUS_LIST } from '@prc-serenity-configs/sidebar-status.config';
import { LayoutInitAction, LayoutUpdateAction } from '../actions/layout.action';
import { ProgressLineSerenityModel } from "@prc-serenity-models/progress-line-serenity.model";

export interface LayoutState {
  progress: ProgressLineSerenityModel;
  sidebarStatus: SidebarStatusList;
  currentStatus: Nullable<SidebarId>;
  displaySidebar: Nullable<boolean>;
  displayProgressBar: Nullable<boolean>;
  displayReviews?: Nullable<boolean>;
  displayBackButton: Nullable<boolean>;
  displayLegalMentionsOnMobile: Nullable<boolean>;
  displayTitleOnHeader: Nullable<boolean>;
}

export const layoutInitialState: LayoutState = {
  progress: {
    currentStep: 0,
    currentSubStep: 0,
    totalSubSteps: 0
  },
  sidebarStatus: SIDEBAR_STATUS_LIST,
  currentStatus: SidebarId.Accommodation,
  displaySidebar: true,
  displayProgressBar: true,
  displayBackButton: true,
  displayReviews: true,
  displayLegalMentionsOnMobile: false,
  displayTitleOnHeader: false,
};

export const layoutReducer = createReducer(
  layoutInitialState,
  on(LayoutInitAction, () => layoutInitialState),
  on(LayoutUpdateAction, (state, { payload }) => {
    const payloadCopy: LayoutState = { ...payload };
    const sidebarStatusCopy = [...payloadCopy.sidebarStatus];

    payloadCopy.sidebarStatus.forEach((status, key) => {
      const stautsCopy = { ...status };
      stautsCopy.isActive = payload.currentStatus === status.id;
      sidebarStatusCopy[key] = stautsCopy;
    });
    payloadCopy.sidebarStatus = sidebarStatusCopy;

    return { ...state, ...payloadCopy };
  })
);
