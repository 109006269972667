import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { EFFY_CREATE_LEAD_SUCCESS_ACTION, GoogleTagManagerService, GoogleTagManagerUtils } from '@prc-lib-core';
import { map, tap } from 'rxjs';

@Injectable()
export class GoogleTagManagerEffects {
  pushPageView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map((action: RouterNavigationAction) => action.payload),
        tap(payload =>
          this.gtmService.pushEvent(
            GoogleTagManagerUtils.gtmFormatter([this.baseHref.slice(0, this.baseHref.length - 1), payload.event.urlAfterRedirects].join(''))
          )
        )
      ),
    { dispatch: false }
  );

  createLeadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EFFY_CREATE_LEAD_SUCCESS_ACTION),
        map(action => action.pisteId),
        tap(pisteId => this.gtmService.pushEventCreateLead(pisteId, 'form-serenite'))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly gtmService: GoogleTagManagerService,
    @Inject(APP_BASE_HREF) private readonly baseHref: string
  ) {}
}
