import { Injectable, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  route = inject(ActivatedRoute);

  getQueryParams(param: string) {
    return this.route.queryParams.pipe(map(params => params[param]));
  }
}
