import { Injectable } from '@angular/core';
import { LeadsService } from '@effy-tech/angular-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { EFFY_CREATE_LEAD_FAILED_ACTION, EFFY_CREATE_LEAD_SUCCESS_ACTION, EffySherlockAction } from '../actions/sherlock.action';

@Injectable()
export class EffySherlockEffects {
  createLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EffySherlockAction.CreateLead),
      switchMap(({ request }) => this.leadsService.createLead(request)),
      mergeMap(result => [EFFY_CREATE_LEAD_SUCCESS_ACTION({ pisteId: result.id })]),
      catchError(() => of(EFFY_CREATE_LEAD_FAILED_ACTION()))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly leadsService: LeadsService
  ) {}
}
