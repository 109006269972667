import { Origin } from '@prc-serenity-configs/origin.config';
import { Sites } from '../../configs/sites.config';
import { SiteUtil } from '../../utils/site.util';
import { StepsUtil } from '../../utils/steps.util';
import { ISite } from './site.service';

export class LeclercService implements ISite {
  utmFunnel = 'Leclerc';
  name = Sites.LECLERC;
  totalSteps = 16;
  logo = SiteUtil.computeSiteLogo(this.name);
  link = StepsUtil.firstStepPath();
  partnerLogoReverse = true;
  isLibertyAllowed = false;
  origin = Origin.LECLERC;
  headerLogoSeparatorLabel = 'avec';
  ineligiblePage = {
    title: 'Nous ne sommes pas en mesure de vous accompagner.<br>RDV sur E.Leclerc énergies pour trouver une solution adaptée !',
    alternativeImage: { src: 'assets/img/none-eligible.png', alt: 'client et leclerc poignée de main'},
    card: {
      title: `Malheureusement, votre projet ne répond pas aux critères d'éligibilité à la prime Effy.<br>Voici une liste des raisons possibles :`,
      listElements: [
        {
          title: 'Le projet concerne un appartement.',
        },
        {
          title: 'Le projet concerne un logement de moins de deux ans.',
        },
        {
          title: 'Aucune offre de notre partenariat ne répond aux besoins de votre projet.',
        },
      ],
      button: {
        label: 'Retourner sur E.Leclerc Energies',
        src: 'https://www.primes-energie.leclerc/souscrire/?utm_source=partner&utm_medium=site&utm_campaign=effy',
      },
    },
  };
}
