<prc-lib-layout
  [displayBackButton$]="displayBackButton$"
  [displayLegalMentionsOnMobile$]="displayLegalMentionsOnMobile$"
  [title$]="title$"
  [displaySidebar$]="displaySidebar$"
  [displayReviewsSidebar$]="displayReviewsSidebar$"
  [isDesktop$]="isDesktop$"
  [sidebarStatusList$]="sidebarStatusList$"
  [legalMentionsLabel]="legalMentionsLabel"
  [displayProgressBar$]="displayProgressBar$"
  [progressInPercent$]="progressInPercent$"
  (clickInfo)="onClickInfo()"
  [partnerLogoSrc]="partnerLogoSrc"
  [partnerLink]="partnerLink"
  [partnerLogoReverse]="partnerLogoReverse"
  [headerLogoSeparatorLabel]="headerLogoSeparatorLabel"
>
  <router-outlet router-outlet></router-outlet>
</prc-lib-layout>
