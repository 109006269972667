<div class="flex justify-center items-center"
     [class.flex-row-reverse]="partnerLogoReverse"
>
  <a [attr.href]="linkUrl">
    <mat-icon class="logo" [svgIcon]="logo"></mat-icon>
  </a>
  <ng-container *ngIf="partnerLogoSrc">
    <span class="font-600 text-[14px] mx-[8px]">{{ headerLogoSeparatorLabel }}</span>
    <a *ngIf="partnerLogoSrc" [attr.href]="partnerLink" class="flex justify-center">
      <span class="flex items-center">
        <img id="partonariat_logo" [src]="partnerLogoSrc" class="partner-logo" />
      </span>
    </a>
  </ng-container>
</div>
