import { Routes } from '@angular/router';
import { StepsPaths } from '@prc-serenity-configs/paths.config';

export const WorksQuestionsRoutes: Routes = [
  {
    path: StepsPaths.INSULATION_TYPE,
    loadComponent: () =>
      import('../../components/pages/works-questions/insulation/insulation-type/insulation-type.component').then(
        component => component.InsulationTypeComponent
      ),
  },
  {
    path: StepsPaths.ATTICS_SURFACE,
    loadComponent: () =>
      import('../../components/pages/works-questions/insulation/attics-surface/attics-surface.component').then(
        component => component.AtticsSurfaceComponent
      ),
  },
  {
    path: StepsPaths.ATTICS_INSULATION_TYPE,
    loadComponent: () =>
      import('../../components/pages/works-questions/insulation/attics-insulation-type/attics-insulation-type.component').then(
        component => component.AtticsInsulationTypeComponent
      ),
  },
  {
    path: StepsPaths.WALLS_SURFACE,
    loadComponent: () =>
      import('../../components/pages/works-questions/insulation/walls-surface/walls-surface.component').then(
        component => component.WallsSurfaceComponent
      ),
  },
  {
    path: StepsPaths.AIR_WATER_HEAT_PUMP_SURFACE,
    loadComponent: () =>
      import('../../components/pages/works-questions/heated-surface/heated-surface.component').then(
        component => component.HeatedSurfaceComponent
      ),
  },
  {
    path: StepsPaths.AIR_WATER_HEAT_PUMP_SANITARY_WATER,
    loadComponent: () =>
      import('../../components/pages/works-questions/sanitary-water-heating/sanitary-water-heating.component').then(
        component => component.SanitaryWaterHeatingComponent
      ),
  },
  {
    path: StepsPaths.AIR_WATER_HEAT_PUMP_EXTERIOR_SPACE,
    loadComponent: () =>
      import('../../components/pages/works-questions/exterior-space/exterior-space.component').then(
        component => component.ExteriorSpaceComponent
      ),
  },
];
