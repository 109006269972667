import { WorkTypeValues } from '../models/works.model';

export type WorkIntentionValues = {
  [key in Exclude<WorkTypeValues, WorkTypeValues.Boiler>]: {
    amount: number;
  };
};

export const WORK_INTENTION_VALUES: WorkIntentionValues = {
  [WorkTypeValues.HeatPump]: {
    amount: 13,
  },
  [WorkTypeValues.WoodStove]: {
    amount: 13,
  },
  [WorkTypeValues.AirWaterHeatPump]: {
    amount: 40,
  },
  [WorkTypeValues.AirAirHeatPump]: {
    amount: 26,
  },
  [WorkTypeValues.CondensingGasBoiler]: {
    amount: 15,
  },
  [WorkTypeValues.CondensingFuelBoiler]: {
    amount: 13,
  },
  [WorkTypeValues.FireplaceInsert]: {
    amount: 13,
  },
  [WorkTypeValues.GeothermalHeatPump]: {
    amount: 20,
  },
  [WorkTypeValues.HybridHeatPump]: {
    amount: 20,
  },
  [WorkTypeValues.StorageHeater]: {
    amount: 13,
  },
  [WorkTypeValues.ThermodynamicWaterHeater]: {
    amount: 13,
  },
  [WorkTypeValues.WoodPelletBoiler]: {
    amount: 20,
  },
  [WorkTypeValues.DoubleGlazing]: {
    amount: 13,
  },
  [WorkTypeValues.WallInsulation]: {
    amount: 30,
  },
  [WorkTypeValues.AtticInsulation]: {
    amount: 22,
  },
  [WorkTypeValues.FloorInsulation]: {
    amount: 16,
  },
  [WorkTypeValues.DoubleFlowCMV]: {
    amount: 15,
  },
  [WorkTypeValues.RoofTerrace]: {
    amount: 13,
  },
  [WorkTypeValues.Aerovoltaic]: {
    amount: 13,
  },
  [WorkTypeValues.CombinedSolarSystem]: {
    amount: 20,
  },
  [WorkTypeValues.PhotovoltaicPanel]: {
    amount: 20,
  },
  [WorkTypeValues.HybridSolar]: {
    amount: 20,
  },
  [WorkTypeValues.SolarWaterHeater]: {
    amount: 20,
  },
  [WorkTypeValues.GlobalRenovation]: {
    amount: 0,
  },
  [WorkTypeValues.EnergyAudit]: {
    amount: 0,
  },
};
