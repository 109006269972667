import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LEGAL_MENTIONS_LABEL } from '../../labels/legal-mentions.label';

@Component({
  selector: 'prc-serenity-modal-legal-mentions',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule],
  templateUrl: './modal-legal-mentions.component.html',
  styleUrls: ['./modal-legal-mentions.component.scss'],
})
export class ModalLegalMentionsComponent {
  readonly legalMentionsLabel = LEGAL_MENTIONS_LABEL;
  constructor(private readonly matDialog: MatDialog) {}

  closeModal(): void {
    this.matDialog.closeAll();
  }
}
