import { createReducer, on } from '@ngrx/store';
import {
  Address,
  City,
  RemoveNullable,
  VulcanExistingEnergyValuesParcoursSerenity,
  VulcanExistingHeatingTypeParcoursSerenity,
  VulcanHousingAgeValues,
  VulcanHousingTypeValues,
} from '@prc-lib-core';
import { AnswersInitAction, AnswersUpdateAction, AnswsersUpdateSomeAction } from '../actions/answers.action';

import { Nullable } from '@prc-lib-core';
import { AnswserKeys, HouseholdIncome, ProjectStatus, TimingProject, UserStatus, WorksType } from '@prc-serenity-models/keys.model';

export interface AnswersState {
  [AnswserKeys.HousingType]: Nullable<VulcanHousingTypeValues>;
  [AnswserKeys.HousingAge]: Nullable<VulcanHousingAgeValues>;
  [AnswserKeys.HousingSurface]: Nullable<number>;
  [AnswserKeys.ExistingEnergy]: Nullable<VulcanExistingEnergyValuesParcoursSerenity>;
  [AnswserKeys.ExistingHeatingType]: Nullable<VulcanExistingHeatingTypeParcoursSerenity>;
  [AnswserKeys.WorksType]: Nullable<WorksType>;
  [AnswserKeys.ProjectStatus]: Nullable<ProjectStatus>;
  [AnswserKeys.TimingProject]: Nullable<TimingProject>;
  [AnswserKeys.UserStatus]: Nullable<UserStatus>;
  [AnswserKeys.HouseholdComposition]: Nullable<number>;
  [AnswserKeys.HouseholdIncome]: Nullable<HouseholdIncome>;
  [AnswserKeys.WorksAddress]: Nullable<Address>;
  [AnswserKeys.HousingZipCode]: Nullable<City>;
}

export type AnswersStateNonNullable = RemoveNullable<AnswersState>;

export type AnswerKeyType = keyof AnswersState;

export const answersInitialState: AnswersState = {
  [AnswserKeys.HousingType]: null,
  [AnswserKeys.HousingAge]: null,
  [AnswserKeys.HousingSurface]: 0,
  [AnswserKeys.ExistingEnergy]: null,
  [AnswserKeys.ExistingHeatingType]: null,
  [AnswserKeys.WorksType]: null,
  [AnswserKeys.ProjectStatus]: null,
  [AnswserKeys.TimingProject]: null,
  [AnswserKeys.UserStatus]: null,
  [AnswserKeys.HouseholdComposition]: null,
  [AnswserKeys.HouseholdIncome]: null,
  [AnswserKeys.WorksAddress]: null,
  [AnswserKeys.HousingZipCode]: null,
};

export const answersReducer = createReducer(
  answersInitialState,
  on(AnswersInitAction, () => answersInitialState),
  on(AnswersUpdateAction, (state, { payload }) => {
    const newState = { ...state, ...payload };
    return newState;
  }),
  on(AnswsersUpdateSomeAction, (state, { payload }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newState = { ...state, ...(payload as any) };
    return newState;
  })
);
