import { createReducer, on } from '@ngrx/store';
import {
  BinaryAnswer,
  Nullable,
  RemoveNullable,
  VulcanAirWaterHeatPumpSanitaryWater,
  VulcanAtticsType,
  VulcanInsulationTypeValues,
  VulcanWorkTypeValues,
} from '@prc-lib-core';
import { AnswserKeys } from '@prc-serenity-models/keys.model';
import {
  AnswersWorksInitAction,
  AnswersWorksUpdateAction,
  AnswersWorksUpdateSomeAction,
} from '@prc-serenity-store/actions/answers-works.action';

export interface AnswsersWorksState {
  [AnswserKeys.InsulationType]: Nullable<VulcanWorkTypeValues>;
  [AnswserKeys.AtticsType]: Nullable<VulcanAtticsType>;
  [AnswserKeys.AtticsSurface]: Nullable<number>;
  [AnswserKeys.AtticsInsulationType]: Nullable<VulcanInsulationTypeValues>;
  [AnswserKeys.WallsSurface]: Nullable<number>;
  [AnswserKeys.AirWaterHeatPumpSurface]: Nullable<number>;
  [AnswserKeys.AirWaterHeatPumpSanitaryWater]: Nullable<VulcanAirWaterHeatPumpSanitaryWater>;
  [AnswserKeys.AirWaterHeatPumpExteriorSpace]: Nullable<BinaryAnswer>;
}

export type AnswersWorksStateNonNullable = RemoveNullable<AnswsersWorksState>;

export type AnswerWorkKeyType = keyof AnswsersWorksState;

export const answersWorksInitialState: AnswsersWorksState = {
  [AnswserKeys.InsulationType]: null,
  [AnswserKeys.AtticsType]: null,
  [AnswserKeys.AtticsSurface]: null,
  [AnswserKeys.AtticsInsulationType]: null,
  [AnswserKeys.WallsSurface]: null,
  [AnswserKeys.AirWaterHeatPumpSurface]: null,
  [AnswserKeys.AirWaterHeatPumpSanitaryWater]: null,
  [AnswserKeys.AirWaterHeatPumpExteriorSpace]: null,
};

export const answersWorksReducer = createReducer(
  answersWorksInitialState,
  on(AnswersWorksInitAction, () => answersWorksInitialState),
  on(AnswersWorksUpdateAction, (state, { payload }) => {
    const newState = { ...state, ...payload };
    return newState;
  }),
  on(AnswersWorksUpdateSomeAction, (state, { payload }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newState = { ...state, ...(payload as any) };
    return newState;
  })
);
