import { UtmModel } from '../models/utm.model';

export class UtmUtil {
  static getUtmFromCookie(utm: string): UtmModel {
    const utmList = utm.split('|').map(e => (e === '' ? undefined : e));

    return {
      medium: utmList[0],
      source: utmList[1],
      campaign: utmList[2],
      content: utmList[3],
      term: utmList[4],
      gclid: utmList[5],
      referrer: utmList[6],
    };
  }
}
