import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService, UserSubscribeNewsletterSource } from '@prc-lib-core';
import { USERS_SUBSCRIBE_NEWSLETTER } from '@prc-serenity-configs/ws.config';
import { switchMap } from 'rxjs';
import { UserActionTypes, UserSubscribeNewsletterPayload } from './../actions/user.action';

@Injectable()
export class UserEffects {
  subscribeNewsletter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActionTypes.SubscribeNewsletter),
        switchMap((payload: UserSubscribeNewsletterPayload) =>
          this.userService
            .subscribeToNewsletter(payload.email, 'optin_newsletter_serenity' as UserSubscribeNewsletterSource, USERS_SUBSCRIBE_NEWSLETTER)
        )
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly userService: UserService) {}
}
