import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_KEY, EFFY_ENVIRONMENT, EffyEnvironment } from '@effy-tech/angular-common';
import { Observable } from 'rxjs';
import { HouseholdIncomePrecarityLevelAnahApiResponse } from '../../models/household-income.model';
import { CacheUtils } from '../../utils/cache.util';
import { getHouseholdIncomeWs } from '../../values/household-income.values';

@Injectable({
  providedIn: 'root',
})
export class HouseholdIncomeService {
  constructor(
    @Inject(EFFY_ENVIRONMENT) private readonly effyEnv: EffyEnvironment,
    @Inject(API_KEY) private readonly apiKey: string,
    private readonly httpClient: HttpClient
  ) {}

  getAnahPrecarityLevels$(householdSize: number, zipCode: string): Observable<HouseholdIncomePrecarityLevelAnahApiResponse> {
    return this.httpClient.get<HouseholdIncomePrecarityLevelAnahApiResponse>(
      getHouseholdIncomeWs(this.effyEnv, this.apiKey, householdSize, zipCode),
      {
        context: CacheUtils.addCacheContextToHttpRequest(),
      }
    );
  }
}
