import { EffyEnvironment } from '@effy-tech/angular-common';

export const getBaseUrlHouseholdIncome = (effyEnv: EffyEnvironment): string => {
  const urlByEnvironment: { [key in EffyEnvironment]: string } = {
    [EffyEnvironment.Dev]: 'https://precaritylevel.dev.api.quelleenergie.net',
    [EffyEnvironment.Rct]: 'https://precaritylevel.rct.api.quelleenergie.net',
    [EffyEnvironment.Prod]: 'https://precaritylevel.api.effy.fr',
  };

  return `${urlByEnvironment[effyEnv]}/v2/tax_revenue`;
};

export const getHouseholdIncomeWs = (effyEnv: EffyEnvironment, apiKey: string, householdSize: number, zipCode: string): string => {
  const addressUrl = new URL(getBaseUrlHouseholdIncome(effyEnv));
  addressUrl.searchParams.append('referencial', '4levels'); // Anah
  addressUrl.searchParams.append('household_size', householdSize.toString());
  addressUrl.searchParams.append('zipcode', zipCode);

  addressUrl.searchParams.append('key', apiKey);

  return addressUrl.toString();
};
