import { Injectable } from '@angular/core';
import { WindowExtended } from '../../models';
import { CookieConsent } from '../../models/cookie-consent.model';

declare let window: WindowExtended;

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  getConsent(): CookieConsent | undefined {
    return window?.CookieConsent?.consent;
  }
}
