import { GTMEvent, WorkTypeValues } from '../models';
import { GTMEventsTypes, GTMPropertiesNames, SpecialUrls, WORK_INTENTION_VALUES } from '../values';

export class GoogleTagManagerUtils {
  private static stepNumber = 1;

  static gtmFormatter(urlAfterRedirect: string): GTMEvent {
    const event: GTMEvent = {
      event: GTMEventsTypes.PAGE_VIEW,
      [GTMPropertiesNames.GaEventPage]: urlAfterRedirect,
      stepNumber: this.stepNumber,
    };

    this.stepNumber = 0;

    return event;
  }

  static ifResultPage(route: string): boolean {
    return route === SpecialUrls.SEEResultPage;
  }

  static getWorksIntentionValue(worksType: WorkTypeValues[]): number {
    if (!worksType) {
      return 0;
    }
    return worksType.reduce((sum: number, workType: WorkTypeValues) => {
      if (workType !== WorkTypeValues.Boiler) {
        const workIntentionValueAmount = WORK_INTENTION_VALUES[workType]?.amount;
        return sum + (workIntentionValueAmount ?? 0);
      }
      return sum;
    }, 0);
  }
}
