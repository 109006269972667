import { enableProdMode, importProvidersFrom } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CostEstimatorService, DEFAULT_THEME, SHERLOCK_AUTH_LEGACY } from '@effy-tech/angular-common';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
  CacheInterceptor,
  EffyIntentionalityEffects,
  EffySherlockEffects,
  LOCATION_TOKEN,
  effyIntentionalityReducer,
  effySherlockReducer,
  provideParcoursConfig,
} from '@prc-lib-core';
import { WindowExtended } from '@prc-serenity-models/window.model';
import { ConfirmationEffects } from '@prc-serenity-store/effects/confirmation.effect';
import { EnrichmentEffect } from '@prc-serenity-store/effects/enrichment.effect';
import { GoogleTagManagerEffects } from '@prc-serenity-store/effects/google-tag-manager.effect';
import { ResultsEffects } from '@prc-serenity-store/effects/results.effect';
import { SherlockEffects } from '@prc-serenity-store/effects/sherlock.effect';
import { UserEffects } from '@prc-serenity-store/effects/user.effect';
import { answersWorksReducer } from '@prc-serenity-store/reducers/answers-works.reducer';
import { answersReducer } from '@prc-serenity-store/reducers/answers.reducer';
import { userReducer } from '@prc-serenity-store/reducers/user.reducer';
import { reducers, reducersConfig, storeDevtoolsModule } from '@prc-serenity-store/store.config';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { BASE_HREF } from './app/core/configs/paths.config';
import { ApiKeyInterceptor } from './app/core/interceptors/api-key.inteceptor';
import { SessionTokenInterceptor } from './app/core/interceptors/session-token/session-token.interceptor';
import { SiteServiceFactory } from './app/core/providers/site.service.provider';
import { SiteService } from './app/core/services/sites/site.service';
import { environment } from './environments/environment';
import { Theme } from '@effy-tech/design-system';

const siteServiceProvider = {
  provide: SiteService,
  useFactory: () => {
    const site = window.prcSite;
    return new SiteServiceFactory().getSiteService(site);
  },
};

registerLocaleData(localeFr);

declare let window: WindowExtended;

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      StoreModule.forRoot(reducers, reducersConfig as any),
      StoreModule.forFeature('effyIntentionality', effyIntentionalityReducer),
      StoreModule.forFeature('effySherlock', effySherlockReducer),
      StoreModule.forFeature('User', userReducer),
      StoreModule.forFeature('AnswersWorks', answersWorksReducer),
      StoreModule.forFeature('Answers', answersReducer),
      EffectsModule.forRoot([
        EnrichmentEffect,
        EffyIntentionalityEffects,
        EffySherlockEffects,
        ConfirmationEffects,
        ResultsEffects,
        SherlockEffects,
        GoogleTagManagerEffects,
        UserEffects,
      ]),
      storeDevtoolsModule,
      StoreRouterConnectingModule.forRoot({
        stateKey: 'router',
      }),
      AppRoutingModule,
      RouterModule
    ),
    siteServiceProvider,
    SiteServiceFactory,
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
    { provide: LOCATION_TOKEN, useValue: window.location },
    provideHttpClient(withInterceptorsFromDi()),
    provideParcoursConfig({
      apiKey: environment.apiKey,
      baseHref: BASE_HREF,
      env: environment.effyEnv,
      featureFlags: { sponsorCodeActive: true },
    }),
    { provide: SHERLOCK_AUTH_LEGACY, useValue: environment.xApi.sherlock },
    CostEstimatorService,
    provideAnimations(),
    { provide: DEFAULT_THEME, useValue: Theme.EFFY },
  ],
}).catch(err => console.error(err));
