import { createReducer, on } from '@ngrx/store';
import { EFFY_CREATE_LEAD_ACTION, EFFY_CREATE_LEAD_SUCCESS_ACTION } from '../actions/sherlock.action';

export interface EffySherlockState {
  pisteId: string | null;
}

export const effySherlockInitialState: EffySherlockState = {
  pisteId: null,
};

export type EffySherlockKeyType = keyof EffySherlockState;

export const effySherlockReducer = createReducer(
  effySherlockInitialState,
  on(EFFY_CREATE_LEAD_ACTION, () => effySherlockInitialState),
  on(EFFY_CREATE_LEAD_SUCCESS_ACTION, (_state, data) => ({ pisteId: data.pisteId }))
);
