import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { TrustpilotMicroTrustscoreComponent } from '../trustpilot-widget/trustpilot-micro-trustscore/trustpilot-micro-trustscore.component';
import { TrustpilotMiniComponent } from '../trustpilot-widget/trustpilot-mini/trustpilot-mini.component';
import { BackButtonComponent } from '../ui';
import { SidebarStatusList } from '../../models';
import { Sites } from '../../values';
import { LogoComponent } from '../ui/logo/logo.component';

@Component({
  selector: 'prc-lib-header',
  standalone: true,
  imports: [
    CommonModule,
    LogoComponent,
    BackButtonComponent,
    MatDialogModule,
    MatIconModule,
    TrustpilotMicroTrustscoreComponent,
    TrustpilotMiniComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string | null = '';
  @Input() isDisplayingBackButton = false;
  @Input() isDisplayReview = false;
  @Input() isDesktop = false;
  @Input() isDisplayingClickInfo = false;
  @Input() partnerLogoSrc = '';
  @Input() partnerLink = '';
  @Input() partnerLogoReverse = false;
  @Input() headerLogoSeparatorLabel!: string;
  @Input() sidebarStatusList: SidebarStatusList | null = [];

  @Output() clickInfo = new EventEmitter<void>();

  readonly EffySite = Sites.EFFY

  readonly step = computed(() => {
    if (this.sidebarStatusList && this.sidebarStatusList.length) {
      const activeStep = this.sidebarStatusList.findIndex(status => status.isActive);
      if (activeStep >= 0) {
        const currentStep = this.sidebarStatusList[activeStep];
        return {
          current: activeStep + 1,
          total: this.sidebarStatusList.length,
          label: currentStep.title,
        };
      }
    }

    return null;
  });

  onClickInfo(): void {
    this.clickInfo.emit();
  }
}
