import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CostEstimatorOfferWithOfferWorkType, EnrichmentService, getPisteId } from '@prc-lib-core';
import { SiteService } from '@prc-serenity-services/sites/site.service';
import { RESULTS_ESTIMATE_SUCCESS_ACTION } from '@prc-serenity-store/actions/results.action';
import { AppState } from '@prc-serenity-store/app-state.model';
import { getUserUid } from '@prc-serenity-store/selectors/user.selector';
import { Observable, combineLatestWith, filter, map, switchMap } from 'rxjs';

@Injectable()
export class EnrichmentEffect {
  pisteId$ = this.store.select(getPisteId).pipe(filter(pisteId => !!pisteId)) as Observable<string>;
  userId$ = this.store.select(getUserUid).pipe(filter(uid => !!uid)) as Observable<string>;
  private readonly siteService = inject(SiteService);

  enrichOnResultsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RESULTS_ESTIMATE_SUCCESS_ACTION),
        map(action => action.costEstimator),
        combineLatestWith(this.pisteId$, this.userId$),
        switchMap(([works, pisteId, userId]) =>
          this.enrichmentService.enrich<CostEstimatorOfferWithOfferWorkType>(this.siteService.utmFunnel, pisteId, userId, works)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly enrichmentService: EnrichmentService,
    private readonly store: Store<AppState>
  ) {}
}
