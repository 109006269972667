<!-- TrustBox widget - Mini -->
<div
  [id]="trustpilotId"
  class="trustpilot-widget"
  [attr.data-text-color]="textColor"
  data-locale="fr-FR"
  data-template-id="53aa8807dec7e10d38f59f32"
  data-businessunit-id="5f2a7644d9d39500016dc5b6"
  data-style-height="100px"
  data-style-width="100%"
  data-theme="light"
>
  <a href="https://fr.trustpilot.com/review/effy.fr" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->
