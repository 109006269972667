import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getHasCampaignAvailable } from '@prc-lib-core';
import { StepsPaths } from '@prc-serenity-configs/paths.config';
import { SherlockService } from '@prc-serenity-services/sherlock/sherlock.service';
import {
  CONFIRMATION_GO_TO_ELIGIBLE_ACTION,
  CONFIRMATION_GO_TO_INELIGIBLE_ACTION,
  CONFIRMATION_INIT_ACTION,
} from '@prc-serenity-store/actions/confirmation.action';
import { RESULTS_ESTIMATE_ACTION } from '@prc-serenity-store/actions/results.action';
import { AppState } from '@prc-serenity-store/app-state.model';
import { iif, map, mergeMap, of, switchMap, take } from 'rxjs';

@Injectable()
export class ConfirmationEffects {
  hasCampaign$ = this.store.select(getHasCampaignAvailable);
  isEligibleForConfirmationWhenHaveCampaign$ = this.sherlockService.getIsEligibleForSerenity$();

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CONFIRMATION_INIT_ACTION),
      switchMap(() => this.hasCampaign$),
      mergeMap(hasCampaign =>
        iif(
          () => hasCampaign,
          this.isEligibleForConfirmationWhenHaveCampaign$.pipe(
            take(1),
            map(isEligible => (isEligible ? CONFIRMATION_GO_TO_ELIGIBLE_ACTION() : CONFIRMATION_GO_TO_INELIGIBLE_ACTION()))
          ),
          of(CONFIRMATION_GO_TO_INELIGIBLE_ACTION())
        )
      )
    )
  );

  goToEligible$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CONFIRMATION_GO_TO_ELIGIBLE_ACTION),
        switchMap(() => this.router.navigate([StepsPaths.CONFIRMATION]))
      ),
    { dispatch: false }
  );

  fetchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CONFIRMATION_GO_TO_ELIGIBLE_ACTION),
      switchMap(() => [RESULTS_ESTIMATE_ACTION()])
    )
  );

  goToNonEligible$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CONFIRMATION_GO_TO_INELIGIBLE_ACTION),
        switchMap(() => this.router.navigate([StepsPaths.NONE_ELIGIBLE]))
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sherlockService: SherlockService,
    private readonly router: Router,
    private readonly store: Store<AppState>
  ) {}
}
