<div class="bg-white flex items-center px-6 slg:px-8 gap-6 slg:gap-8 h-[72px] slg:h-[80px]">
  <prc-lib-back-button *ngIf="isDisplayingBackButton" class="flex lg:hidden"></prc-lib-back-button>
  <mat-icon (click)="onClickInfo()" *ngIf="isDisplayingClickInfo" class="block cursor-pointer lg:!hidden">info_outline</mat-icon>
  <prc-lib-logo
    class="[--logo-width:43px] slg:[--logo-width:88px] z-10"
    [partnerLogoSrc]="partnerLogoSrc"
    [partnerLink]="partnerLink"
    [partnerLogoReverse]="partnerLogoReverse"
    [headerLogoSeparatorLabel]="headerLogoSeparatorLabel"
  ></prc-lib-logo>
  @if (isDesktop) {
    <span class="font-600 text-md"> {{ title }} </span>
  } @else if (step() !== null) {
    <span class="flex flex-col text-sm">
      <div class="font-700">
        <span>{{ step()!.current }}</span>
        <span>/{{ step()!.total }}</span>
      </div>
      <span class="font-600 text-blue-power">{{ step()!.label }}</span>
    </span>
  }
</div>
